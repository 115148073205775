<script lang="ts">
  import SidenavFooter from "$lib/components/dashboard/SidenavFooter.svelte";

  export let showHat = false;

  const tupplets = [
    "Tuppy!",
    "Tupo",
    "Tupple",
    "Tupplet",
    "Tupplebox",
    "Tuperbot",
    "Tupperbot",
    "Tupr",
    "Tupperbix",
    "Tubber Bot",
    "Tupperbo",
    "Tupperboc",
    "Tuperbox",
    "Tubberbox",
    "Tuppler",
    "Tuller",
    "Trupper",
    "TuppberBox",
    "Tuplo",
    "Tuppa!",
    "The Boy™",
    "Tuppets",
  ];
  const remainingTupplets = [...tupplets];
  let activeTupplet = "Tupperbox";
  let tuppletElement: HTMLHeadingElement;
  let tuppletButton: HTMLButtonElement;
  let bankedClicks = 0;

  let minWidth = 0;
  $: minWidth = Math.max(tuppletButton?.clientWidth || 0, minWidth);

  function cycleTupplet() {
    const i = Math.floor(Math.random() * remainingTupplets.length);
    activeTupplet = remainingTupplets.splice(i, 1)[0];
    if (remainingTupplets.length === 0) {
      remainingTupplets.push(...tupplets);
    }
    const randomNumber =
      Math.random() > bankedClicks++ / 100
        ? Math.floor(Math.random() * 21) - 10
        : Math.floor(Math.random() * 720) + 360;
    tuppletElement.animate(
      [
        { transform: "scale(1) rotate(0deg)" },
        {
          transform: `scale(${1.1 + 0.025 * bankedClicks}) rotate(${randomNumber}deg)`,
        },
        { transform: "scale(1) rotate(0deg)" },
      ],
      {
        duration: randomNumber > 10 ? 500 : 250,
        easing: "ease-out",
      }
    );
    if (randomNumber > 10) bankedClicks = 0;
  }
</script>

<footer class="container" class:has-hat={showHat}>
  <div class="avatar-title">
    <img alt="Tupperbox Avatar" src="/logo.svg" />
    <button
      bind:this={tuppletButton}
      on:click={cycleTupplet}
      style="min-width: {minWidth}px"
    >
      <h1 bind:this={tuppletElement}>
        {activeTupplet}
      </h1>
    </button>
  </div>
  <SidenavFooter />
</footer>

<style lang="scss">
  footer {
    border-top: 4px solid lighten($primary, 20);
    background-color: $dark;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    @include breakpoint(md) {
      flex-direction: row;
    }

    &.has-hat {
      border: none;
      padding-top: 2rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    .avatar-title {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        margin-right: 1rem;
      }
      button {
        all: unset;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          transform: scale(1.05);
        }
        &:focus {
          h1 {
            outline: 2px solid white;
            border-radius: 0.75rem;
          }
        }

        h1 {
          display: inline-block;
          margin: 0;
          padding: 0 1rem;
        }
      }
    }
  }
</style>
